<template>
  <div>
    <NavigationBar
      titel="Neues Team anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form
              @submit.prevent="createItem"
              v-model="formIsValid"
              class="col s8"
            >
              <v-col cols="12">
                <Subheader
                  icon="mdi-account-box-multiple"
                  title="Allgemein"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model.trim="bezeichnung"
                              :counter="32"
                              label="Bezeichnung"
                              required
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                            <v-textarea
                              v-model.trim="beschreibung"
                              counter
                              clearable
                              no-resize
                              label="Beschreibung"
                              outlined
                            ></v-textarea>
                            <v-select
                              v-model="kategorie"
                              :items="kategorienSelect"
                              item-text="bezeichnung"
                              return-object
                              label="Kategorie"
                              prepend-inner-icon="mdi-shape"
                              outlined
                            >
                            </v-select>
                            <v-text-field
                              v-model="farbe"
                              label="Farbe wählen"
                              persistent-hint
                              required
                              disabled
                              outlined
                              prepend-inner-icon="mdi-palette"
                            ></v-text-field>
                            <v-color-picker
                              v-model="farbe"
                              hide-inputs
                              hide-canvas
                              hide-sliders
                              show-swatches
                              width="1200"
                              swatches-max-height="150"
                            ></v-color-picker>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12">
                <Subheader
                  icon="mdi-account-box-multiple"
                  title="Mitglieder"
                ></Subheader>
                <div class="mt-7">
                  <v-alert
                    border="left"
                    colored-border
                    type="info"
                    elevation="2"
                  >
                    Du musst zunächst das Team anlegen, um diesem Mitglieder
                    hinzuzufügen.
                  </v-alert>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { MY_ORGANIZATION } from "@/store/modules.js";
import { GET_KATEGORIEN, CREATE_TEAM } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";

export default {
  name: "team-new",
  components: {
    NavigationBar,
    Subheader,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      formIsValid: false,

      bezeichnung: null,
      beschreibung: null,
      farbe: "#F44336",
      mitgliederanzahl: 0,
      kategorie: null,
    };
  },
  computed: {
    kategorienSelect() {
      return this.$store.state.myOrganization.categories;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MY_ORGANIZATION}${GET_KATEGORIEN}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    createItem() {
      if (this.formIsValid) {
        this.$store.dispatch(`${MY_ORGANIZATION}${CREATE_TEAM}`, {
          organizationId: this.$route.params.organizationId,
          bezeichnung: this.bezeichnung,
          beschreibung: this.beschreibung,
          mitgliederanzahl: this.mitgliederanzahl,
          farbe: this.farbe,
          kategorie: this.kategorie,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
    createTeam() {},
  },
};
</script>

<style></style>
