<template>
  <div>
    <NavigationBar
      titel="Neue Kategorie anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form
              @submit.prevent="createItem"
              v-model="formIsValid"
              class="col s8"
            >
              <v-col cols="12">
                <Subheader icon="mdi-shape" title="Allgemein"></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model.trim="bezeichnung"
                              :counter="32"
                              label="Bezeichnung"
                              required
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                            <v-textarea
                              v-model.trim="beschreibung"
                              counter
                              clearable
                              no-resize
                              label="Beschreibung"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { MY_ORGANIZATION } from "@/store/modules.js";
import { CREATE_KATEGORIE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";

export default {
  name: "my-organization-category-new",
  components: {
    NavigationBar,
    Subheader,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      bezeichnung: null,
      beschreibung: null,

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      formIsValid: false,
    };
  },

  methods: {
    createItem() {
      if (this.formIsValid) {
        this.$store.dispatch(`${MY_ORGANIZATION}${CREATE_KATEGORIE}`, {
          organizationId: this.$route.params.organizationId,
          bezeichnung: this.bezeichnung,
          beschreibung: this.beschreibung,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
