<template>
  <div>
    <NavigationBar titel="Teams"></NavigationBar>
    <section>
      <TippCard
        hintID="Meine_Organisation_Teams_1_V1"
        text="Teams bilden die Aufgabenbereiche, Ausbildungsgruppen, Führungskräfte usw. deiner Organisation ab. Mitglieder können mehreren Teams zugeordnet werden. Teams können Aufgaben, Termine etc. verwalten."
      >
      </TippCard>
    </section>
    <!-- <section v-if="meineTeams">
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12" >
              <Subheader
                icon="mdi-account-box-multiple-outline"
                title="Meine Teams"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="meineTeams"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  :items-per-page="5"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-card
                          link
                          v-bind:style="createBorderColorStyle(item.farbe)"
                        >
                          <v-card-title class="subheading">
                            {{ item.bezeichnung }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.beschreibung }}
                          </v-card-subtitle>
                          <v-card-text>
                            <span>
                              <v-icon small>mdi-account-group</v-icon>
                              {{ item.mitgliederanzahl }} Mitglieder
                            </span>
                            <span
                              v-if="
                                (item.mitgliederanzahl !== undefined || null) &&
                                  (item.kategorie !== undefined || null)
                              "
                            >
                              –
                            </span>
                            <span v-if="item.kategorie !== undefined || null">
                              <v-icon small>mdi-shape</v-icon>
                              {{ item.kategorie.bezeichnung }}
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section> -->
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-account-box-multiple"
                title="Teams"
                :actions="actionsTeams"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="teams"
                  :search="search"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  :items-per-page="5"
                  no-data-text="Keine Teams vorhanden"
                >
                  <!-- <template v-slot:header>
                    <v-toolbar outlined flat class="mb-4">
                      <v-text-field
                        v-model="search"
                        clearable
                        flat
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Suche"
                        style="max-width:380px"
                      ></v-text-field>
                      <template>
                        <v-spacer></v-spacer>
                        <v-btn-toggle dense v-model="sortDesc" mandatory>
                          <v-btn outlined :value="true">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn outlined :value="false">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </template>
                    </v-toolbar>
                  </template> -->

                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-card
                          link
                          @click="routeTeams(item.meta.id)"
                          v-bind:style="createBorderColorStyle(item.farbe)"
                        >
                          <v-card-title class="subheading">
                            {{ item.bezeichnung }}
                            <!-- <v-spacer></v-spacer>
                            <v-menu bottom left offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, i) in menuItemsTeam"
                                  :key="i"
                                  link
                                  :disabled="item.disabled"
                                  @click="item.function"
                                >
                                  <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>{{
                                    item.title
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu> -->
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.beschreibung }}
                          </v-card-subtitle>
                          <v-card-text>
                            <span>
                              <v-icon small>mdi-account-group</v-icon>
                              {{ item.mitgliederanzahl }} Mitglieder
                            </span>
                            <span
                              v-if="
                                (item.mitgliederanzahl !== undefined || null) &&
                                (item.kategorie !== undefined || null)
                              "
                            >
                              –
                            </span>
                            <span v-if="item.kategorie !== undefined || null">
                              <v-icon small>mdi-shape</v-icon>
                              {{ item.kategorie.bezeichnung }}
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-shape"
                title="Kategorien"
                :actions="actionsKategorien"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="categories"
                  :search="search"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  :items-per-page="5"
                  no-data-text="Keine Kategorien vorhanden"
                >
                  <!-- <template v-slot:header>
                    <v-toolbar outlined flat class="mb-4">
                      <v-text-field
                        v-model="search"
                        clearable
                        flat
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Suche"
                        style="max-width:380px"
                      ></v-text-field>
                      <template>
                        <v-spacer></v-spacer>
                        <v-btn-toggle dense v-model="sortDesc" mandatory>
                          <v-btn outlined :value="true">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn outlined :value="false">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </template>
                    </v-toolbar>
                  </template> -->

                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-card link @click="routeKategorien(item.meta.id)">
                          <v-card-title class="subheading">
                            {{ item.bezeichnung }}
                            <!-- <v-spacer></v-spacer>
                            <v-menu bottom left offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, i) in menuItemsKategorie"
                                  :key="i"
                                  link
                                  :disabled="item.disabled"
                                  @click="item.function"
                                >
                                  <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>{{
                                    item.title
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu> -->
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.beschreibung }}
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { GET_TEAMS, GET_KATEGORIEN } from "@/store/action-types.js";
import {
  MY_ORGANIZATION_TEAMS_TEAMS_create,
  MY_ORGANIZATION_TEAMS_CATEGORIES_create,
} from "@/data/permission-types.js";

export default {
  name: "my-organization-teams",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    TippCard,
  },
  data() {
    return {
      sortDesc: true,
      sortBy: "bezeichnung",

      actionsTeams: [
        {
          title: "Erstellen",
          icon: "mdi-plus-box-multiple",
          permission: `${MY_ORGANIZATION_TEAMS_TEAMS_create}`,
          actionStyle: "textBtn",
          function: this.teamErstellen,
        },
      ],
      actionsKategorien: [
        {
          title: "Erstellen",
          icon: "mdi-shape-plus",
          permission: `${MY_ORGANIZATION_TEAMS_CATEGORIES_create}`,
          actionStyle: "textBtn",
          function: this.kategorieErstellen,
        },
      ],

      // menuItemsTeam: [
      //   {
      //     disabled: false,
      //     title: "Bearbeiten",
      //     icon: "mdi-pencil",
      //     function: this.test,
      //   },
      //   {
      //     disabled: false,
      //     title: "Löschen",
      //     icon: "mdi-delete",
      //     function: this.test,
      //   },
      // ],
      // menuItemsKategorie: [
      //   {
      //     disabled: false,
      //     title: "Bearbeiten",
      //     icon: "mdi-pencil",
      //     function: this.test,
      //   },
      //   {
      //     disabled: false,
      //     title: "Löschen",
      //     icon: "mdi-delete",
      //     function: this.test,
      //   },
      // ],
    };
  },
  computed: {
    meineTeams() {
      return [];
      //return this.$store.state.myOrganization.meineTeams;
    },
    teams() {
      return this.$store.state.myOrganization.teams;
    },
    categories() {
      return this.$store.state.meineOrganisation.categories;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "meineTeams", data: this.meineTeams },
        { title: "teams", data: this.teams },
        { title: "categories", data: this.categories },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      // this.$store.dispatch(`${MY_ORGANIZATION}${GET_USERS_TEAMS}`, {
      //   organizationId: this.$route.params.organizationId,
      // });
      this.$store.dispatch(`${MY_ORGANIZATION}${GET_TEAMS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${MY_ORGANIZATION}${GET_KATEGORIEN}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    teamErstellen() {
      this.$router.push({ name: "my-organization-team-new" });
    },
    routeTeams(item) {
      this.$router.push({
        name: "my-organization-team-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    kategorieErstellen() {
      this.$router.push({ name: "my-organization-category-new" });
    },
    routeKategorien(item) {
      this.$router.push({
        name: "my-organization-category-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    createBorderColorStyle(farbe) {
      const style = "border-left: 12px solid " + farbe;
      return style;
    },
  },
};
</script>
