<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-shape" title="Informationen"></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-title class="subheading">
                    {{ data.bezeichnung }}
                  </v-card-title>
                  <v-card-subtitle>
                    {{ data.beschreibung }}
                  </v-card-subtitle>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-account-box-multiple"
                title="Teams dieser Kategorie"
              ></Subheader>
              <v-divider></v-divider>
              <div class="mt-7">
                <v-data-iterator
                  :items="teamsQuery"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  items-per-page="5"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-card
                          link
                          @click="routeDetails(item.meta.id)"
                          v-bind:style="createBorderColorStyle(item.farbe)"
                        >
                          <v-card-title class="subheading">
                            {{ item.bezeichnung }}
                            <!-- <v-spacer></v-spacer>
                            <v-menu bottom left offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, i) in menuItemsTeam"
                                  :key="i"
                                  link
                                  :disabled="item.disabled"
                                  @click="item.function"
                                >
                                  <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>{{
                                    item.title
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu> -->
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.beschreibung }}
                          </v-card-subtitle>
                          <v-card-text>
                            <span>
                              <v-icon small>mdi-account-group</v-icon>
                              {{ item.mitgliederanzahl }} Mitglieder
                            </span>
                            <span
                              v-if="
                                (item.mitgliederanzahl !== undefined || null) &&
                                (item.kategorie !== undefined || null)
                              "
                            >
                              –
                            </span>
                            <span v-if="item.kategorie !== undefined || null">
                              <v-icon small>mdi-shape</v-icon>
                              {{ item.kategorie.bezeichnung }}
                            </span>
                          </v-card-text>
                          <v-divider></v-divider>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { DELETE_KATEGORIE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import {
  MY_ORGANIZATION_TEAMS_CATEGORIES_update,
  MY_ORGANIZATION_TEAMS_CATEGORIES_delete,
} from "@/data/permission-types.js";

export default {
  name: "my-organization-category-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MY_ORGANIZATION_TEAMS_CATEGORIES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permissions: `${MY_ORGANIZATION_TEAMS_CATEGORIES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      sortDesc: true,
      sortBy: "bezeichnung",
      teamsQuery: [],

      data: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "data", data: this.data },
        { title: "teamsQuery", data: this.teamsQuery },
      ];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("meine_organisation")
        .doc("teams")
        .collection("kategorien")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("meine_organisation")
        .doc("teams")
        .collection("teams")
        .where("kategorie.id", "==", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              bezeichnung: doc.data().bezeichnung,
              beschreibung: doc.data().beschreibung,
              farbe: doc.data().farbe,
              mitgliederanzahl: doc.data().mitgliederanzahl,
              kategorie: {
                bezeichnung: doc.data().kategorie.bezeichnung,
                id: doc.data().kategorie.id,
              },
              meta: {
                id: doc.data().meta.id,
              },
            };
            this.teamsQuery.push(data);
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    createBorderColorStyle(farbe) {
      const style = "border-left: 12px solid " + farbe;
      return style;
    },
    routeDetails(item) {
      this.$router.push({
        name: "my-organization-team-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    editItem() {
      this.$router.push({
        name: "my-organization-category-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      if (!this.teamsQuery.length > 0) {
        var res = confirm(
          "Bist Du sicher, dass Du diese Kategorie löschen möchtest?"
        );
        if (res == true) {
          this.$store.dispatch(`${MY_ORGANIZATION}${DELETE_KATEGORIE}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,
          });
        }
      } else {
        alert(
          "Fehler: Du kannst nur Kategorien löschen, denen keine Teams zugeordnet sind."
        );
      }
    },
  },
};
</script>

<style></style>
