<template>
  <div>
    <NavigationBar
      titel="Team bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-form
              @submit.prevent="updateItem"
              v-model="formIsValid"
              class="col s8"
            >
              <v-col cols="12">
                <Subheader
                  icon="mdi-account-box-multiple"
                  title="Allgemein"
                ></Subheader>
                <div class="mt-7">
                  <v-card>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model.trim="data.bezeichnung"
                              :counter="32"
                              label="Bezeichnung"
                              required
                              :rules="[rules.required]"
                              outlined
                            ></v-text-field>
                            <v-textarea
                              v-model.trim="data.beschreibung"
                              counter
                              clearable
                              no-resize
                              label="Beschreibung"
                              outlined
                            ></v-textarea>
                            <v-select
                              v-model="data.kategorie"
                              :items="kategorienSelect"
                              item-text="bezeichnung"
                              return-object
                              label="Kategorie"
                              prepend-inner-icon="mdi-shape"
                              outlined
                            >
                            </v-select>
                            <v-text-field
                              v-model="data.farbe"
                              label="Farbe wählen"
                              persistent-hint
                              required
                              disabled
                              outlined
                              prepend-inner-icon="mdi-palette"
                            ></v-text-field>
                            <v-color-picker
                              v-model="data.farbe"
                              hide-inputs
                              hide-canvas
                              hide-sliders
                              show-swatches
                              width="1200"
                              swatches-max-height="150"
                            ></v-color-picker>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12">
                <Subheader
                  icon="mdi-account-box-multiple"
                  title="Mitglieder"
                ></Subheader>
                <div class="mt-7">
                  <v-alert
                    border="left"
                    colored-border
                    type="info"
                    elevation="2"
                  >
                    Du musst zunächst das Team anlegen, um diesem Mitglieder
                    hinzuzufügen.
                  </v-alert>
                </div>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { GET_KATEGORIEN, UPDATE_TEAM } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";

export default {
  name: "my-organization-team-edit",
  components: {
    NavigationBar,
    Subheader,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      formIsValid: false,
      data: null,
    };
  },
  computed: {
    kategorienSelect() {
      return this.$store.state.myOrganization.categories;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MY_ORGANIZATION}${GET_KATEGORIEN}`, {
        organizationId: this.$route.params.organizationId,
      });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("meine_organisation")
        .doc("teams")
        .collection("teams")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateItem() {
      if (this.formIsValid) {
        this.$store.dispatch(`${MY_ORGANIZATION}${UPDATE_TEAM}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
          bezeichnung: this.data.bezeichnung,
          beschreibung: this.data.beschreibung,
          mitgliederanzahl: this.data.mitgliederanzahl,
          farbe: this.data.farbe,
          kategorie: this.data.kategorie,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
